function trackEvent(eventName, eventData = null, source = null, userProperties = null) {
  if (typeof gtag === 'function' && source === 'google') {
    gtag('event', eventName, eventData);
  }

  if (source === 'brevo') {
    Brevo.track(eventName, userProperties, eventData);
  }

  if (typeof amplitude === 'object' && !source) {
    amplitude.track(eventName, {
      ...eventData,
    });
  }
}

export { trackEvent };
